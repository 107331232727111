<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import { Ref, ref, useAttrs, watch } from 'vue'

import { URIContext } from '@ankor-io/common/uri/Uri'
import { SolidSampleImage } from '@ankor-io/icons/solid'

type Props = {
  /**
   * The id of the image
   */
  id?: string
  /**
   * the width descriptors
   */
  widthDescriptors?: string[]
  /**
   * The relative url path of the image
   *    /media/{mediaUri}
   *    {mediaUri}
   *  https://www.yahoo.com/foo.jpg
   */
  url?: string
  /**
   * The blur base64 of the image
   */
  blur?: string
  /**
   * The uri of the entity the asset belongs to
   */
  uri?: string
  /**
   * The asset filename assets/filename.ext
   */
  filename?: string
}

const props = withDefaults(defineProps<Props>(), {
  widthDescriptors: () => ['1280w', '320w', '640w', '960w', '2560w'],
})

const imageRef: any = ref(null)
const hasError: Ref<boolean> = ref(false)
const hasLoaded: Ref<boolean> = ref(false)

const getSrc = () => {
  if (props.url && ['/undefined', '/null', '//'].some((str) => props.url!.includes(str))) {
    hasError.value = true
    return ''
  }

  if (props.filename && ['/undefined', '/null', '//'].some((str) => props.filename!.includes(str))) {
    hasError.value = true
    return ''
  }

  if (props.url || props.filename?.startsWith(`${URIContext.MEDIA}::`)) {
    return `${props.url}/${props.widthDescriptors[0]}`
  } else if (props.uri && props.filename) {
    console.debug('AssetViewer path is being created using a uri and filename', [props.uri, props.filename])
    return `/media/media::image::${props.uri!}::${props.filename!.replaceAll('/', '::')}/${props.widthDescriptors[0]}`
  }

  return '' // should not be triggered
}

const srcSet = () => {
  if (props.uri && props.filename) {
    return props.widthDescriptors
      .map(
        (descriptor: string) =>
          `/media/media::image::${props.uri!}::${props.filename!.replaceAll('/', '::')}/${descriptor} ${descriptor}`,
      )
      .reduce((acc: string, comb: string) => {
        return `${acc}, ${comb}`
      })
  }

  if (props.url?.startsWith('/media/media::image')) {
    return props.widthDescriptors
      .map((descriptor: string) => `${props.url}/${descriptor} ${descriptor}`)
      .reduce((acc: string, comb: string) => {
        return `${acc}, ${comb}`
      })
  }

  if (props.url?.startsWith('media::image')) {
    return props.widthDescriptors
      .map((descriptor: string) => `/media/${props.url}/${descriptor} ${descriptor}`)
      .reduce((acc: string, comb: string) => {
        return `${acc}, ${comb}`
      })
  }

  return ''
}

const onImageError = () => {
  hasError.value = true
}

watch(
  () => props.url,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      hasError.value = false
    }
  },
)

defineExpose({ imageRef })
</script>
<template>
  <!-- Slot allows the parent to override this placeholder -->
  <slot
    v-if="hasError || (!props.url && !props.filename) || props.url === '/media/' || props.filename === '/media/'"
    name="placeholder"
  >
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
      :class="twMerge('w-full h-full', useAttrs().class as string)"
      :id="props.id || props.uri || props.url"
    />
  </slot>
  <template v-else>
    <img
      v-if="!hasLoaded && props.blur"
      :src="props.blur"
      :class="twMerge(`w-full h-full`, useAttrs().class as string)"
    />
    <img
      loading="lazy"
      :class="twMerge(hasLoaded ? 'w-full h-full' : 'h-0 w-0', useAttrs().class as string)"
      :src="getSrc()"
      :srcset="srcSet()"
      :id="props.id || props.uri || props.url"
      :ref="(el) => (imageRef = el)"
      :data-image-path="props.filename"
      :onerror="onImageError"
      @load="hasLoaded = true"
    />
    <!-- Skeleton loader when the blur is not provided and loading the image -->
    <div
      v-if="props.url && !hasLoaded && !props.blur"
      role="status"
      class="animate-pulse flex items-center justify-center w-full h-full bg-gray-200 rounded dark:bg-gray-400"
    >
      <SolidSampleImage class="w-full h-full bg-gray-200 fill-gray-400" :class="$attrs.class" />
    </div>
  </template>
</template>
