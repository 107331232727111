<script lang="ts" setup>
import html2pdf from 'html2pdf.js'
import ls from 'localstorage-slim'
import { Ref, Teleport, computed, ref } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import MegaModal from '@ankor-io/blocks/components/modals/MegaModal.vue'
import SimpleAlertModal from '@ankor-io/blocks/components/modals/SimpleAlertModal.vue'
import { modalHelper } from '@ankor-io/blocks/components/modals/modalHelper'
import { showAwaitToast } from '@ankor-io/blocks/components/toast'
import ListGrid from '@ankor-io/blocks/layouts/ListGrid.vue'
import ListTable from '@ankor-io/blocks/layouts/ListTable.vue'
import ListTableAccordion from '@ankor-io/blocks/layouts/ListTableAccordion.vue'
import { URIContext } from '@ankor-io/common/uri/Uri'
import { MediaUriBuilder } from '@ankor-io/common/uri/uri.builder'
import { SolidX } from '@ankor-io/icons/solid'
import { SolidCollection, SolidRoute, SolidSearch, SolidTable } from '@ankor-io/icons/solid'

import SearchNoResultsMoon from '@/assets/search-no-results-moon.svg'
import SearchNoResultsPresentation from '@/assets/search-no-results-presentation.svg'
import VisibleObserver from '@/components/VisibleObserver.vue'
import { useStowageService } from '@/services/stowage/StowageService'
import { usePreferredViewStore } from '@/stores/preferredView/preferredView.store'
import { durationToDays, epochAsLabel } from '@/utils/date'

import RoutePreview from './RoutePreview.vue'
import RoutesActionPreview from './RoutesActionPreview.vue'
import RoutesActionsKebab from './RoutesActionsKebab.vue'
import Tags from './Tags.vue'

const UNTITLED_ROUTE = 'Untitled Route'

// the data in the table -- the dirty one has "null" values for tombstoned items.
let dirtyRiver: Ref<any[]> = ref([])
const river = computed(() => dirtyRiver.value.filter((item) => !!item))
const preferredViewStore = usePreferredViewStore()

//
// During the "transform items" step of the Aloglia search API,
// accumulate the results in the river array.
const accumulateSearchResults = (items: any[], { results }: any): object => {
  //  ** IMPORTANT **
  //  There is no guarantee that this function will be called only once
  //    for each call to refineNext() or via an Algolia HTTP call. See:
  //   - https://github.com/algolia/vue-instantsearch/issues/707#issuecomment-1361526922
  //

  // reset river to empty array if it's the first page
  if (results.page === 0) {
    dirtyRiver.value = []
  }

  items.forEach((hit, i) => {
    let offset = results.page * results.hitsPerPage + i

    if (ls.get<string>(`tombstone::${hit.uri}`) !== null && ls.get<string>(`tombstone::${hit.uri}`) !== undefined) {
      dirtyRiver.value[offset] = null
      return
    }

    // work out the hero image url
    let _hero
    if (hit.hero && hit.hero.startsWith(`${URIContext.MEDIA}::`)) {
      _hero = `/media/${hit.hero}`
    } else {
      _hero = `/media/${new MediaUriBuilder().build(hit.uri, hit.hero)}`
    }

    // set the hit to the expected row position aligning to the position of the hit.
    dirtyRiver.value[offset] = {
      ...hit,
      uri: hit.objectID,
      hero: _hero,
      name: hit.line_1,
      numDays: durationToDays(hit.line_5),
      embarkation: hit.line_3,
      disembarkation: hit.line_4,
      tags: hit.tags,
      dateEdited: epochAsLabel(hit.lastModified),
    }
  })

  // return the clean river (used by algolia lib)
  return river
}

//
// Modal helpers
const previewModalHelper = modalHelper()
const deleteModalHelper = modalHelper()
const stowage = useStowageService()
const requestDeleteDocument = async (uri: string, name: string) => {
  // hide the modal
  deleteModalHelper.hide()

  // delete & show toast
  return showAwaitToast(
    stowage.deleteDocument(uri),
    `Deleting <em>${name}</em>...`,
    `<em>${name}</em> deleted.`,
    `<em>${name}</em> could not be deleted.`,
  ).then(() => {
    // tombstone the itinerary locally
    ls.set(`tombstone::${uri}`, 'true')
    // clear it from the river results
    const idx = dirtyRiver.value.indexOf(dirtyRiver.value.find((item) => item && item.uri === uri))
    dirtyRiver.value[idx] = null
  })
}

const printPdf = (name: string) => {
  // hide the preview modal
  previewModalHelper.hide()

  const printSection = document.getElementById('route-preview')
  const heading = document.getElementById('route-preview-header')
  heading?.classList.toggle('hidden')

  const options = {
    margin: 4,
    filename: `${name || 'itinerary'}.pdf`,
    image: { type: 'jpeg', quality: 1 },
    html2canvas: { scale: 1 },
  }

  // generate & show toast
  return showAwaitToast(
    html2pdf()
      .set(options)
      .from(printSection)
      .save()
      .then(() => {
        heading?.classList.toggle('hidden')
      }),
    `Generating PDF for <em>${name}</em>...`,
    `Finished generating PDF for <em>${name}</em>.`,
    `Failed to generate PDF.`,
  )
}
</script>
<template>
  <ais-configure filters="type:itinerary AND NOT tags:tombstone" hitsPerPage="36" />

  <!-- Delete modal -->
  <Teleport defer to="body">
    <SimpleAlertModal
      v-if="deleteModalHelper.isVisible()"
      buttonText="Yes, Delete"
      :model="deleteModalHelper.model"
      @confirm="(model: any) => [deleteModalHelper.hide(), requestDeleteDocument(model.uri, model.name)]"
      @dismiss="deleteModalHelper.hide()"
    >
      <template #title="{ modelValue }">
        <h3>{{ modelValue.title }}</h3>
      </template>
      <template #default="{ modelValue }">
        <div class="space-y-2.5 text-center text-sm text-gray-500 dark:text-gray-400">
          <p>You are about to permanently delete</p>
          <p class="font-bold">{{ modelValue.name }}</p>
          <p>Are you sure you want to proceed?</p>
        </div>
      </template>
    </SimpleAlertModal>
  </Teleport>

  <!-- Preview modal -->
  <Teleport defer to="body">
    <MegaModal
      v-if="previewModalHelper.isVisible()"
      :model="previewModalHelper.model"
      @dismiss="previewModalHelper.hide()"
    >
      <template #header="{ modelValue }">
        <div class="flex justify-between items-center">
          <div>
            <h4>{{ modelValue.title }}</h4>
            <div
              v-if="modelValue.region || modelValue.numDays || modelValue.tags"
              class="flex items-center gap-x-2 text-sm text-gray-500 dark:text-gray-400"
            >
              <p v-if="modelValue.region">{{ modelValue.region }}</p>
              <p v-if="modelValue.numDays">{{ modelValue.numDays }} {{ modelValue.numDays === 1 ? 'Day' : 'Days' }}</p>
              <Tags v-if="modelValue.tags" :tags="modelValue.tags" />
            </div>
          </div>

          <div class="flex items-center gap-x-2">
            <RoutesActionPreview
              :uri="modelValue.uri"
              :canMutate="!modelValue.uri.startsWith('a::')"
              @downloadPDFClick="printPdf(modelValue.title)"
              @deleteClick="
                () =>
                  deleteModalHelper.show({
                    title: 'Delete this route?',
                    uri: modelValue.uri,
                    name: modelValue.title,
                  })
              "
            />

            <!-- FIXME: add this X to the modal so that it does not scroll away -->
            <button
              type="button"
              class="transition-colors rounded-md text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 focus:outline-none focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-500"
              @click="previewModalHelper.hide()"
            >
              <span class="sr-only">Close</span>
              <SolidX class="size-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </template>

      <template #default="{ modelValue }">
        <div class="min-h-24 overflow-y-auto border-t border-gray-200 dark:border-gray-600">
          <RoutePreview id="route-preview" :uri="modelValue.uri" />
        </div>
      </template>
    </MegaModal>
  </Teleport>

  <div class="grid grid-cols-1 gap-4">
    <div class="flex flex-col gap-4 sm:flex-row justify-between items-start sm:items-center">
      <h3 class="sm:h-10" data-testId="page-title">Routes</h3>
      <RouterLink
        target="_blank"
        class="w-full sm:w-auto justify-center transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center text-white bg-primary-600 dark:bg-primary-500 hover:bg-primary-800 dark:hover:bg-primary-800 focus:ring-primary-300 dark:focus:ring-primary-300"
        :to="{ name: 'trips-editor-routes-new' }"
      >
        <SolidRoute class="size-5 mr-2 self-center" />
        New Route
      </RouterLink>
    </div>

    <div class="w-full flex flex-col md:flex-row justify-between items-baseline gap-2 pb-4 min-h-11">
      <div class="w-full flex flex-col sm:flex-row items-start sm:items-center gap-3">
        <div class="w-full sm:w-auto flex gap-x-2 items-center">
          <!-- nunununununununun -->
          <!--   Category Box    -->
          <!-- nunununununununun -->
          <ais-menu-select
            attribute="tags"
            :class-names="{
              'ais-MenuSelect': 'tags-selector',
            }"
          >
            <template v-slot="{ refine }">
              <select
                class="w-48 h-11 z-20 border-1 rounded-lg focus:ring-0 bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500"
                @change="refine(($event.currentTarget! as HTMLInputElement).value)"
              >
                <option class="" value="">All Routes</option>
                <option class="" value="ankor">Ankor Routes</option>
                <option class="" value="custom">My Routes</option>
              </select>
            </template>
          </ais-menu-select>

          <!-- nunununununununun -->
          <!--    Search Box     -->
          <!-- nunununununununun -->
          <ais-search-box
            placeholder="Search for anything..."
            submit-title="Submit"
            reset-title="clear"
            :autofocus="true"
            :show-loading-indicator="false"
            :class-names="{
              'ais-SearchBox': 'h-11 w-full',
              'ais-SearchBox-form':
                'p-3 h-full flex items-center gap-x-2 relative rounded-lg border bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500',
              'ais-SearchBox-input':
                'bg-transparent order-2 h-full w-full z-20 outline-none border-none focus:ring-0 focus:border-0 focus:outline-0 placeholder-gray-500 text-gray-500',
              'ais-SearchBox-reset': 'hidden',
              'ais-SearchBox-loadingIcon': 'hidden',
            }"
          >
            <template v-slot:submit-icon>
              <SolidSearch
                class="order-first solid-search z-20 pointer-events-none w-5 h-5 stroke-2 fill-general-text-body"
              />
            </template>
          </ais-search-box>

          <!--  nunununununununun  -->
          <!--         Sort        -->
          <!--  nunununununununun  -->
          <ais-sort-by
            :items="[
              { value: 'global', label: 'Most Relevant' },
              { value: 'global_line_1_asc', label: 'Name (A-Z)' },
              { value: 'global_line_1_desc', label: 'Name (Z-A)' },
              // this is the duration; sorts alpha.
              // { value: 'global_line_5_asc', label: 'Days (Highest)' },
              // { value: 'global_line_5_desc', label: 'Days (Lowest)' },
              { value: 'global_last_modified_desc', label: 'Recently Edited' },
            ]"
            :class-names="{
              'ais-SortBy-select':
                'h-11 z-20 border-1 rounded-lg focus:ring-0 bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500',
            }"
          />
        </div>

        <!-- nunununununununun -->
        <!--       Views       -->
        <!-- nunununununununun -->
        <div class="border-b border-gray-200 dark:border-gray-700">
          <ul class="flex flex-nowrap -mb-px text-center text-gray-500 dark:text-gray-400">
            <li>
              <button
                @click.stop="preferredViewStore.preferredView = 'table'"
                class="transition-colors inline-flex items-center justify-center p-2 border-b-2 rounded-t-lg group"
                :class="[
                  preferredViewStore.preferredView === 'table'
                    ? 'active text-primary-600 dark:text-primary-500 border-primary-600 dark:border-primary-500'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-400 dark:hover:border-gray-400 dark:text-gray-500',
                ]"
              >
                <SolidTable class="size-4 me-2" />Table
              </button>
            </li>
            <li>
              <button
                @click.stop="preferredViewStore.preferredView = 'card'"
                class="transition-colors inline-flex items-center justify-center p-2 border-b-2 rounded-t-lg group"
                :class="[
                  preferredViewStore.preferredView === 'card'
                    ? 'active text-primary-600 dark:text-primary-500 border-primary-600 dark:border-primary-500'
                    : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-400 dark:hover:border-gray-400 dark:text-gray-500',
                ]"
              >
                <SolidCollection class="size-4 me-2" />Cards
              </button>
            </li>
          </ul>
        </div>
      </div>

      <!--  nunununununununun  -->
      <!--        Stats        -->
      <!--  nunununununununun  -->
      <ais-stats class="hidden xl:block text-sm min-w-36 text-right text-gray-50 dark:text-gray-500">
        <template v-slot="{ nbHits }">
          <template v-if="nbHits == 0">
            <span class="hidden">No Routes</span>
          </template>
          <template v-else-if="nbHits == 1"> 1 Route </template>
          <template v-else> {{ nbHits }} Routes </template>
        </template>
      </ais-stats>
    </div>
  </div>

  <!-- nunununununununun -->
  <!--  Search Results   -->
  <!-- nunununununununun -->
  <ais-infinite-hits :transform-items="accumulateSearchResults">
    <template v-slot="{ refineNext, isLastPage, results }">
      <div id="searchResults-top"></div>

      <!-- Getting Started and No Results-->
      <!-- add '?showGetStarted=1' to show that screen manually -->
      <template v-if="!results.nbHits || $route.query.showGetStarted">
        <slot name="no-results">
          <template v-if="!results.query && !results.params.includes('facetFilters')">
            <!-- Show the getting started diagram. -->
            <div class="grid grid-cols-1 gap-4 justify-items-center pt-16">
              <div class="h-[33vh]">
                <!-- TODO: Update to a route no results? -->
                <img :src="SearchNoResultsPresentation" alt="No results found" class="h-full" />
              </div>
              <h4>Looks like you have no routes yet!</h4>
              <div>Get started by creating your first route.</div>
              <div>
                <RouterLink
                  target="_blank"
                  class="transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center text-white bg-primary-600 hover:bg-primary-700 focus:ring-primary-300 dark:focus:ring-primary-800"
                  :to="{ name: 'trips-editor-routes-new' }"
                >
                  <SolidRoute class="size-5 mr-2 self-center" />
                  New Route
                </RouterLink>
              </div>
            </div>
          </template>
          <template v-else>
            <!-- No results. -->
            <div class="grid grid-cols-1 gap-4 justify-items-center pt-16">
              <div class="h-[33vh]">
                <img :src="SearchNoResultsMoon" alt="No results found" class="h-full" />
              </div>
              <h4>No results.</h4>
              <div>Sorry! We could not find anything to match your query.</div>
            </div>
          </template>
        </slot>
      </template>

      <!-- Show results -->
      <template v-else>
        <!-- ... in grid cards -->
        <template v-if="preferredViewStore.preferredView === 'card'">
          <ListGrid :keyField="'uri'" :items="river">
            <template #item="{ item }">
              <!-- Route Card -->
              <div>
                <button
                  type="button"
                  class="w-full aspect-[1.44] relative hover:scale-105 focus:ring-4 focus:outline-none focus:ring-primary-300 cursor-pointer rounded-lg transition-transform duration-500 ease-in-out"
                  aria-label="Preview Route"
                  @click="
                    previewModalHelper.show({
                      uri: item.uri,
                      title: item.name,
                      tags: item.tags,
                      region: item.line_6,
                      numDays: item.numDays,
                    })
                  "
                >
                  <div v-if="item.tags" class="absolute top-2 left-2">
                    <Tags :tags="item.tags" :labels="{ ankor: 'Ankor Route' }" />
                  </div>
                  <AssetViewerPlaceholder
                    class="w-full object-cover shadow-md rounded-lg"
                    :url="item.hero"
                    :blur="item.blur"
                    :widthDescriptors="['320w']"
                  />
                  <div
                    v-if="item.numDays"
                    class="w-12 absolute right-4 -bottom-2.5 p-2 rounded-md flex flex-col justify-center shadow-md bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300"
                  >
                    <!-- Update to use real data -->
                    <span class="text-xl font-bold">{{ item.numDays }}</span>
                    <span class="uppercase text-xs font-medium">
                      {{ item.numDays === 1 ? 'Day' : 'Days' }}
                    </span>
                  </div>
                </button>
                <!-- Route Card Text Section -->
                <div class="mt-2 flex gap-x-1">
                  <h6 class="w-full self-center line-clamp-2">
                    {{ item.name || UNTITLED_ROUTE }}
                  </h6>
                  <RoutesActionsKebab
                    :uri="item.uri"
                    :canMutate="!item.uri.startsWith('a::')"
                    @deleteClick="
                      () =>
                        deleteModalHelper.show({
                          title: 'Delete this route?',
                          uri: item.uri,
                          name: item.name,
                        })
                    "
                  />
                </div>
                <p class="text-sm line-clamp-2">{{ item.embarkation }} -> {{ item.disembarkation }}</p>
              </div>
            </template>
            <template #after>
              <template v-if="!isLastPage">
                <VisibleObserver @visible="refineNext" />
              </template>
              <template v-else="isLastPage">
                <div class="mt-8 text-center text-sm">You have reached the end of the results!</div>
              </template>
            </template>
          </ListGrid>
        </template>
        <!-- ... in a table -->
        <template v-else>
          <ListTable
            rowKey="uri"
            class="hidden md:table w-full table-fixed"
            :columns="[
              { label: 'Name', field: 'name', type: 'string', classes: { th: 'w-2/5', td: 'truncate' } },
              { label: 'Days', field: 'numDays', type: 'number', classes: { th: 'w-12' } },
              { label: 'Embarkation', field: 'embarkation', type: 'string', classes: { th: 'w-2/6', td: 'truncate' } },
              {
                label: 'Disembarkation',
                field: 'disembarkation',
                type: 'string',
                classes: { th: 'w-2/6', td: 'truncate' },
              },
              {
                label: 'Last Edited',
                field: 'dateEdited',
                type: 'string',
                classes: { th: 'w-28', td: 'whitespace-nowrap' },
              },
              { label: '', field: 'actions', type: 'number', classes: { th: 'w-14' } },
            ]"
            :rows="river"
            :classes="{ thead: 'top-16', tr: 'cursor-pointer' }"
            @rowClick="
              ({ row, field }) =>
                'actions' !== field &&
                previewModalHelper.show({
                  uri: row.uri,
                  title: row.name,
                  tags: row.tags,
                  region: row.line_6,
                  numDays: row.numDays,
                })
            "
          >
            <!-- column: IMAGE/NAME/TAGS -->
            <template #name="{ row }">
              <div class="flex items-center gap-x-2">
                <div class="min-w-20 max-w-20 h-12">
                  <AssetViewerPlaceholder
                    class="object-cover w-full h-full rounded"
                    :url="row.hero"
                    :blur="row.blur"
                    :widthDescriptors="['320w']"
                  />
                </div>
                <div class="w-full flex justify-between self-center">
                  <span class="font-semibold truncate">{{ row.name }}</span>
                  <Tags :tags="row.tags" :labels="{ ankor: 'Ankor Route' }" />
                </div>
              </div>
            </template>
            <!-- column: ACTION MENU -->
            <template #actions="{ row }">
              <RoutesActionsKebab
                :uri="row.uri"
                :canMutate="!row.uri.startsWith('a::')"
                @deleteClick="
                  () =>
                    deleteModalHelper.show({
                      title: 'Delete this route?',
                      uri: row.uri,
                      name: row.name,
                    })
                "
              />
            </template>
            <template #after>
              <div class="hidden md:block">
                <template v-if="!isLastPage">
                  <VisibleObserver @visible="refineNext" />
                </template>
                <template v-else="isLastPage">
                  <div class="mt-8 text-center text-sm">You have reached the end of the results!</div>
                </template>
              </div>
            </template>
          </ListTable>
          <ListTableAccordion
            rowKey="uri"
            class="block md:hidden px-3"
            :columns="[
              { label: 'Name', field: 'name', type: 'string', classes: { th: 'w-full', td: 'w-full truncate' } },
              { label: 'Days', field: 'numDays', type: 'number', classes: { th: 'w-14' } },
              { label: '', field: 'actions', type: 'number', classes: { th: 'w-14' } },
            ]"
            :rows="river"
            :classes="{ thead: 'top-16', tr: 'cursor-pointer' }"
            @rowClick="
              ({ row, field }) =>
                'actions' !== field &&
                previewModalHelper.show({ uri: row.uri, title: row.name, tags: row.tags, region: row.line_6 })
            "
          >
            <!-- column: NAME -->
            <template #name="{ row }">
              <div class="flex items-center gap-x-2">
                <div class="min-w-20 max-w-20 h-12">
                  <AssetViewerPlaceholder
                    class="object-cover w-full h-full rounded"
                    :url="row.hero"
                    :blur="row.blur"
                    :widthDescriptors="['320w']"
                  />
                </div>
                <span class="font-semibold truncate">{{ row.name }}</span>
              </div>
            </template>
            <!-- column: ACTION MENU -->
            <template #actions="{ row }">
              <RoutesActionsKebab
                :uri="row.uri"
                :canMutate="!row.uri.startsWith('a::')"
                @deleteClick="
                  () =>
                    deleteModalHelper.show({
                      title: 'Delete this route?',
                      uri: row.uri,
                      name: row.name,
                    })
                "
              />
            </template>
            <!-- Accordion content -->
            <template #accordion-content="{ row }">
              <template
                v-for="col of [
                  { label: 'Embarkation', field: 'embarkation', type: 'string' },
                  { label: 'Disembarkation', field: 'disembarkation', type: 'string' },
                  { label: 'Tags', field: 'tags', type: 'string' },
                  { label: 'Last Edited', field: 'dateEdited', type: 'string' },
                ]"
                :key="col.label"
              >
                <div v-if="row[col.field]" class="flex flex-col gap-y-1">
                  <span class="text-xs font-semibold text-gray-700 dark:text-gray-300">
                    {{ col.label }}
                  </span>
                  <Tags v-if="col.field === 'tags'" :tags="row.tags" />
                  <span v-else class="text-sm text-gray-500 dark:text-gray-400">
                    {{ row[col.field] }}
                  </span>
                </div>
              </template>
            </template>
            <!-- Observer -->
            <template #after>
              <div class="block md:hidden">
                <template v-if="!isLastPage">
                  <VisibleObserver @visible="refineNext" />
                </template>
                <template v-else="isLastPage">
                  <div class="mt-8 text-center text-sm">You have reached the end of the results!</div>
                </template>
              </div>
            </template>
          </ListTableAccordion>
        </template>
      </template>
    </template>
  </ais-infinite-hits>
</template>
